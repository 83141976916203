<template>
  <v-container fluid class="dashboard-new pt-0 width-90">
    <v-row>
      <v-col md="8">
        <div class="grey lighten-3 pa-5">
          <div class="white greeting-card rounded-xl">
            <div class="pl-14">
              <h1>
                Hello, <span class="orange--text">{{ user_name }}</span>
              </h1>
              <p
                v-if="quote"
                class="mt-4 m-0 font-size-16 font-weight-500"
                style="font-size: 18px"
              >
                {{ quote }}
              </p>
              <p
                v-if="!pageLoading && is_lead_pending"
                class="mt-4 m-0 font-size-16 font-weight-500"
              >
                <v-icon color="orange">mdi-alert-decagram-outline</v-icon
                >&nbsp;&nbsp;Some leads are waiting for action.
                <router-link
                  :to="{ name: 'admin.lead', query: { 'lead-action': 1 } }"
                  >Click here to view.</router-link
                >
              </p>
            </div>
            <div class="pr-15">
              <img width="100%" height="150px" :src="quote_image" alt="" />
            </div>
          </div>
        </div>
        <div class="mt-4 grey lighten-3 px-5 pt-5 pb-12">
          <div class="">
            <h3>Lead Status</h3>
          </div>
          <div class="dash-card mt-5 d-flex">
            <div class="card-custom white rounded-xl">
              <v-hover v-slot="{ hover }" open-delay="100">
                <div class="inner-wrap text-center">
                  <div class="pa-4">
                    <v-icon
                      :color="`${hover ? 'white' : 'blue'}`"
                      :class="`blue ${
                        hover ? '' : 'lighten-4'
                      } pa-3 rounded-lg`"
                      >mdi-chart-line</v-icon
                    >
                  </div>
                  <div class="px-3 py-0">
                    <h5>Total Lead</h5>
                  </div>
                  <div
                    :class="`counter ${
                      hover ? 'blue white--text' : 'blue--text'
                    } rounded-xl pa-3`"
                  >
                    <h1>{{ lead.total }}</h1>
                  </div>
                </div>
              </v-hover>
            </div>
            <div class="card-custom ml-5 white rounded-xl">
              <v-hover v-slot="{ hover }" open-delay="100">
                <div class="inner-wrap text-center">
                  <div class="pa-4">
                    <v-icon
                      :color="`${hover ? 'white' : 'orange'}`"
                      :class="`orange ${
                        hover ? '' : 'lighten-4'
                      } pa-3 rounded-lg`"
                      >mdi-chart-sankey</v-icon
                    >
                  </div>
                  <div class="px-3 py-0">
                    <h5>Lead to Prospect</h5>
                  </div>
                  <div
                    :class="`counter ${
                      hover ? 'orange white--text' : 'orange--text'
                    } rounded-xl pa-3`"
                  >
                    <h1>{{ lead.prospect }}</h1>
                  </div>
                </div>
              </v-hover>
            </div>
            <div v-if="false" class="card-custom ml-5 white rounded-xl">
              <v-hover v-slot="{ hover }" open-delay="100">
                <div class="inner-wrap text-center">
                  <div class="pa-4">
                    <v-icon
                      :color="`${hover ? 'white' : 'green'}`"
                      :class="`${
                        hover ? '' : 'lighten-4'
                      } green pa-3 rounded-lg`"
                      >mdi-chart-pie</v-icon
                    >
                  </div>
                  <div class="px-3 py-0">
                    <h5>Appointment Made</h5>
                  </div>
                  <div
                    :class="`counter ${
                      hover ? 'green white--text' : 'green--text'
                    } rounded-xl pa-3`"
                  >
                    <h1>{{ lead.appointment }}</h1>
                  </div>
                </div>
              </v-hover>
            </div>
            <div class="card-custom ml-5 white rounded-xl">
              <v-hover v-slot="{ hover }" open-delay="100">
                <div class="inner-wrap text-center">
                  <div class="pa-4">
                    <v-icon
                      :color="`${hover ? 'white' : 'red'}`"
                      :class="`red ${hover ? '' : 'lighten-4'} pa-3 rounded-lg`"
                      >mdi-chart-bell-curve</v-icon
                    >
                  </div>
                  <div class="px-3 py-0">
                    <h5>Dead Lead</h5>
                  </div>
                  <div
                    :class="`counter ${
                      hover ? 'red white--text' : 'red--text'
                    } rounded-xl pa-3`"
                  >
                    <h1>{{ lead.dead }}</h1>
                  </div>
                </div>
              </v-hover>
            </div>
            <div class="card-custom ml-5 white rounded-xl">
              <v-hover v-slot="{ hover }" open-delay="100">
                <div class="inner-wrap text-center">
                  <div class="pa-4">
                    <v-icon
                      :color="`${hover ? 'white' : 'orange'}`"
                      :class="`orange ${
                        hover ? '' : 'lighten-4'
                      } pa-3 rounded-lg`"
                      >mdi-chart-box</v-icon
                    >
                  </div>
                  <div class="px-3 py-0">
                    <h5>Lead to Customer</h5>
                  </div>
                  <div
                    :class="`counter ${
                      hover ? 'orange white--text' : 'orange--text'
                    } rounded-xl pa-3`"
                  >
                    <h1>{{ lead.customer }}</h1>
                  </div>
                </div>
              </v-hover>
            </div>
            <div
              v-if="false"
              class="card-custom ml-5 gradient-4 white rounded-xl"
            >
              <v-hover v-slot="{ hover }" open-delay="100">
                <div class="inner-wrap text-center">
                  <div class="pa-4">
                    <v-icon
                      :color="`${hover ? 'white' : 'red'}`"
                      :class="`red ${hover ? '' : 'lighten-4'} pa-3 rounded-lg`"
                      >mdi-chart-bell-curve</v-icon
                    >
                  </div>
                  <div class="mb-4">
                    <h4>Uncontacted</h4>
                  </div>
                  <div class="counter red--text">
                    <h1>340</h1>
                  </div>
                </div>
              </v-hover>
            </div>
            <div
              v-if="false"
              class="card-custom ml-5 gradient-5 white rounded-xl"
            >
              <v-hover v-slot="{ hover }" open-delay="100">
                <div class="inner-wrap text-center">
                  <div class="pa-4">
                    <v-icon
                      :color="`${hover ? 'white' : 'orange'}`"
                      :class="`orange ${
                        hover ? '' : 'lighten-4'
                      } pa-3 rounded-lg`"
                      >mdi-chart-box</v-icon
                    >
                  </div>
                  <div class="px-3 py-0">
                    <h4>Pending</h4>
                  </div>
                  <div class="counter orange--text pa-4">
                    <h1>340</h1>
                  </div>
                </div>
              </v-hover>
            </div>
          </div>
        </div>
        <div
          v-if="false"
          class="mt-4 grey lighten-3 px-5 pt-5 pb-5 dashboard-listing"
        >
          <div class="d-flex mb-4">
            <h3>My Meetings</h3>
          </div>
          <div style="max-height: 400px; overflow-y: scroll; min-height: 423px">
            <table width="100%" class="dashboard-table thead-sticky">
              <thead class="grey lighten-4">
                <th>Name</th>
                <th>Location</th>
                <th>Date</th>
                <th>Time</th>
                <th>Status</th>
                <th width="100px">Action</th>
              </thead>
              <tbody v-if="appointments.length">
                <v-hover
                  v-slot="{ hover }"
                  v-for="(row, index) in appointments"
                  :key="index"
                >
                  <tr :class="`${hover ? 'blue white--text' : 'white'}`">
                    <td>{{ row.title }}</td>
                    <td>{{ row.location }}</td>
                    <td>{{ row.date }}</td>
                    <td class="nowrap">{{ row.time }}</td>
                    <td>
                      <v-chip
                        v-if="row.status == 1"
                        label
                        color="green white--text"
                        small
                        >Completed</v-chip
                      >
                      <v-chip
                        v-if="row.status == 2"
                        label
                        color="orange white--text"
                        small
                        >Pending</v-chip
                      >
                      <v-chip
                        v-if="row.status == 3"
                        label
                        color="red lighten-1 white--text"
                        small
                        >Cancelled</v-chip
                      >
                      <v-chip
                        v-if="row.status == 4"
                        label
                        color="cyan white--text"
                        small
                        >Re-Scheduled</v-chip
                      >
                    </td>
                    <td>
                      <v-btn
                        tile
                        depressed
                        fab
                        small
                        height="30"
                        width="30"
                        class="mr-2 blue lighten-4"
                        v-on:click="edit_meeting(row)"
                      >
                        <v-icon size="16" icon color="blue">mdi-pencil</v-icon>
                      </v-btn>
                      <v-menu
                        top
                        v-model="row.menu"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            tile
                            depressed
                            fab
                            small
                            height="30"
                            width="30"
                            class="red lighten-1 white--text"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small dark>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <DeleteSmallConfirm
                          :delete-loading="deleteLoading"
                          :message="`Are you sure, you want to delete ${row.title} ?`"
                          v-on:cancel="row.menu = false"
                          v-on:success="delete_meeting(row.id)"
                        ></DeleteSmallConfirm>
                      </v-menu>
                    </td>
                  </tr>
                </v-hover>
              </tbody>
              <tfoot v-else>
                <tr>
                  <td colspan="6">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-14"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no meeting at the moment.
                    </p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </v-col>
      <v-col md="4">
        <div class="bg-white static-card">
          <div class="pa-3 grey lighten-3">
            <div class="white rounded-lg pa-3">
              <v-layout>
                <v-flex md8 class="my-auto">
                  <h3 class="mb-0">Lead Value</h3>
                </v-flex>
                <v-flex md4 class="text-right">
                  <v-menu
                    bottom
                    origin="center center"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs, value }">
                      <v-btn
                        color="cyan"
                        text
                        class="custom-bold-button"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ fyear }}
                        <v-icon v-if="value"
                          >mdi-chevron-down mdi-rotate-180</v-icon
                        >
                        <v-icon v-else>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(item, i) in fyearList"
                        :key="i"
                        :class="{ 'list-item-selected': item.value == fyear }"
                        v-on:click="filterYear(item)"
                      >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-flex>
              </v-layout>
              <apexchart
                type="bar"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="12">
        <div class="pa-1 grey lighten-3 mt-5">
          <div class="white rounded-lg">
            <Notes :notes="notes" />
          </div>
        </div>
      </v-col>
      <v-col v-if="!pageLoading" md="12">
        <div class="grey lighten-3 px-5 pt-5 pb-5 dashboard-listing">
          <v-layout class="dashboard-filter">
            <v-flex sm4 md4 lg4 xl6 class="my-auto">
              <h3>Leads by Status</h3>
            </v-flex>
            <v-flex sm4 md4 lg4 xl6 class="d-flex align-center">
              <v-text-field
                dense
                class="no-border"
                filled
                color="cyan"
                placeholder="Search..."
                hide-details
                solo
                flat
                clearable
                v-model="leadByStatusFilter.search"
                v-on:change="filterStatus('search')"
              ></v-text-field>
              <template v-if="!is_id_executive">
                <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs, value }">
                    <v-btn
                      color="cyan"
                      text
                      class="custom-bold-button"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ leadByStatusFilter.id_user_text }}
                      <v-icon v-if="value"
                        >mdi-chevron-down mdi-rotate-180</v-icon
                      >
                      <v-icon v-else>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, i) in fidList"
                      :key="i"
                      :class="{
                        'list-item-selected':
                          item.value == leadByStatusFilter.id_user,
                      }"
                      v-on:click="filterStatus('id_user', item)"
                    >
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs, value }">
                  <v-btn
                    color="cyan"
                    text
                    class="custom-bold-button"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ lodash.lowerCase(leadByStatusFilter.status) }}
                    <v-icon v-if="value"
                      >mdi-chevron-down mdi-rotate-180</v-icon
                    >
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in fstatusList"
                    :key="i"
                    :class="{
                      'list-item-selected':
                        item.value == leadByStatusFilter.status,
                    }"
                    v-on:click="filterStatus('status', item)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs, value }">
                  <v-btn
                    color="cyan"
                    text
                    class="custom-bold-button"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ leadByStatusFilter.year }}
                    <v-icon v-if="value"
                      >mdi-chevron-down mdi-rotate-180</v-icon
                    >
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in fyearList"
                    :key="i"
                    :class="{
                      'list-item-selected':
                        item.value == leadByStatusFilter.year,
                    }"
                    v-on:click="filterStatus('year', item)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs, value }">
                  <v-btn
                    color="cyan"
                    text
                    class="custom-bold-button"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ leadByStatusFilter.month }}
                    <v-icon v-if="value"
                      >mdi-chevron-down mdi-rotate-180</v-icon
                    >
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in fmonthList"
                    :key="i"
                    :class="{
                      'list-item-selected':
                        item.value == leadByStatusFilter.month,
                    }"
                    v-on:click="filterStatus('month', item)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
          <div style="max-height: 400px; overflow-y: scroll">
            <table width="100%" class="dashboard-table thead-sticky">
              <thead class="grey lighten-4">
                <th>Lead</th>
                <th>Created At</th>
                <th>Assigned To</th>
                <th>Lead Status</th>
                <th>Converted to Prospect</th>
                <th>Converted to Customer</th>
              </thead>
              <tbody v-if="leadByStatus.length">
                <v-hover
                  v-slot="{ hover }"
                  v-for="(row, index) in leadByStatus"
                  :key="index"
                >
                  <tr :class="`${hover ? 'blue white--text' : 'white'}`">
                    <td>
                      <p class="mb-0">
                        <Barcode
                          v-if="getPermission('lead:view')"
                          route="lead.detail"
                          :id="row.lead_id"
                          small
                          :barcode="row.lead_barcode"
                        ></Barcode>
                        <Barcode
                          v-else
                          small
                          :barcode="row.lead_barcode"
                        ></Barcode>
                      </p>
                      <p class="mb-0">{{ row.lead_first_name }}</p>
                    </td>
                    <td>
                      {{ formatDateTime(row.lead_added_at) }}
                    </td>
                    <td>
                      {{ row.user_display_name }}
                    </td>
                    <td>
                      <v-chip
                        label
                        small
                        class="custom-status font-weight-600 custom-grey-border text-uppercase mx-2"
                        :color="row.status_color"
                        :text-color="row.status_textcolor"
                      >
                        {{ row.status_text }} 
                      </v-chip>
                    </td>
                    <td>
                      {{ formatDateTime(row.prospect_added_at) }}
                    </td>
                    <td>
                      {{ formatDateTime(row.customer_added_at) }}
                    </td>
                  </tr>
                </v-hover>
              </tbody>
              <tfoot v-else>
                <tr>
                  <td colspan="6">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-14"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no lead at the moment.
                    </p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </v-col>
      <v-col v-if="!pageLoading" md="12">
        <div class="grey lighten-3 px-5 pt-5 pb-5 dashboard-listing">
          <v-layout class="dashboard-filter">
            <v-flex sm4 md4 lg4 xl6 class="my-auto">
              <h3>Leads by Stages</h3>
            </v-flex>
            <v-flex sm4 md4 lg4 xl6 class="d-flex align-center">
              <v-text-field
                dense
                class="no-border"
                filled
                color="cyan"
                placeholder="Search..."
                hide-details
                solo
                flat
                clearable
                v-model="leadByStageFilter.search"
                v-on:change="filterStage('search')"
              ></v-text-field>
              <template v-if="!is_id_executive">
                <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs, value }">
                    <v-btn
                      color="cyan"
                      text
                      class="custom-bold-button"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ leadByStageFilter.id_user_text }}
                      <v-icon v-if="value"
                        >mdi-chevron-down mdi-rotate-180</v-icon
                      >
                      <v-icon v-else>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, i) in fidList"
                      :key="i"
                      :class="{
                        'list-item-selected':
                          item.value == leadByStageFilter.id_user,
                      }"
                      v-on:click="filterStage('id_user', item)"
                    >
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs, value }">
                  <v-btn
                    color="cyan"
                    text
                    class="custom-bold-button"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ lodash.lowerCase(leadByStageFilter.status) }}
                    <v-icon v-if="value"
                      >mdi-chevron-down mdi-rotate-180</v-icon
                    >
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in fstatusList"
                    :key="i"
                    :class="{
                      'list-item-selected':
                        item.value == leadByStageFilter.status,
                    }"
                    v-on:click="filterStage('status', item)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs, value }">
                  <v-btn
                    color="cyan"
                    text
                    class="custom-bold-button"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ leadByStageFilter.year }}
                    <v-icon v-if="value"
                      >mdi-chevron-down mdi-rotate-180</v-icon
                    >
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in fyearList"
                    :key="i"
                    :class="{
                      'list-item-selected':
                        item.value == leadByStageFilter.year,
                    }"
                    v-on:click="filterStage('year', item)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs, value }">
                  <v-btn
                    color="cyan"
                    text
                    class="custom-bold-button"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ leadByStageFilter.month }}
                    <v-icon v-if="value"
                      >mdi-chevron-down mdi-rotate-180</v-icon
                    >
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in fmonthList"
                    :key="i"
                    :class="{
                      'list-item-selected':
                        item.value == leadByStageFilter.month,
                    }"
                    v-on:click="filterStage('month', item)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
          <div style="max-height: 400px; overflow-y: scroll">
            <table width="100%" class="dashboard-table thead-sticky">
              <thead class="grey lighten-4">
                <th>Lead</th>
                <th>Assigned To</th>
                <th>Lead Status</th>
                <th>Quote</th>
                <th>Quote Status</th>
                <th>Customer</th>
                <th>Project</th>
                <th>Project Status</th>
              </thead>
              <tbody v-if="leadByStages.length">
                <v-hover
                  v-slot="{ hover }"
                  v-for="(row, index) in leadByStages"
                  :key="index"
                >
                  <tr :class="`${hover ? 'blue white--text' : 'white'}`">
                    <td>
                      <p class="mb-0">
                        <Barcode
                          v-if="getPermission('lead:view')"
                          route="lead.detail"
                          :id="row.lead_id"
                          small
                          :barcode="row.lead_barcode"
                        ></Barcode>
                        <Barcode
                          v-else
                          small
                          :barcode="row.lead_barcode"
                        ></Barcode>
                      </p>
                      <p class="mb-0">{{ row.lead_first_name }}</p>
                    </td>
                    <td>
                      {{ row.user_display_name }}
                    </td>
                    <td>       
                      <v-chip
                        v-if="row.lead_status_obj.id"
                        label
                        small
                        class="custom-status font-weight-600 custom-grey-border text-uppercase mx-2"
                        :color="row.lead_status_obj.color"
                        :text-color="row.lead_status_obj.textcolor"
                      >
                        {{ row.lead_status_obj.text }} 
                      </v-chip>
                    </td>
                    <td>
                      <template v-if="row.quotation_id">
                        <Barcode
                          v-if="getPermission('pre-quotation:view')"
                          route="pre.quotation.detail"
                          :id="row.quotation_id"
                          small
                          :barcode="row.quotation_barcode"
                        ></Barcode>
                        <Barcode
                          v-else
                          small
                          :barcode="row.quotation_barcode"
                        ></Barcode>
                      </template>
                      <span v-else>-</span>
                    </td>
                    <td>
                      <v-chip
                        v-if="row.quotation_status_obj.id"
                        label
                        small
                        class="custom-status font-weight-600 custom-grey-border text-uppercase mx-2"
                        :color="row.quotation_status_obj.color"
                        :text-color="row.quotation_status_obj.textcolor"
                      >
                        {{ row.quotation_status_obj.text }} 
                      </v-chip>
                      <span v-else>-</span>
                    </td>
                    <td>
                      <p class="mb-0">
                        <template v-if="row.customer_id">
                          <Barcode
                            v-if="getPermission('customer:view')"
                            route="customer.detail"
                            :id="row.customer_id"
                            small
                            :barcode="row.customer_barcode"
                          ></Barcode>
                          <Barcode
                            v-else
                            small
                            :barcode="row.customer_barcode"
                          ></Barcode>
                        </template>
                        <span v-else>-</span>
                      </p>
                      <p class="mb-0">{{ row.customer_display_name }}</p>
                    </td>
                    <td>
                      <p class="mb-0">
                        <template v-if="row.project_id">
                          <Barcode
                            v-if="getPermission('project:view')"
                            route="project.detail"
                            :id="row.project_id"
                            small
                            :barcode="row.project_barcode"
                          ></Barcode>
                          <Barcode
                            v-else
                            small
                            :barcode="row.project_barcode"
                          ></Barcode>
                        </template>
                        <span v-else>-</span>
                      </p>
                      <p class="mb-0">{{ row.project_name }}</p>
                    </td>
                    <td>
                      <v-chip
                        v-if="row.project_status_obj.id"
                        label
                        small
                        class="custom-status font-weight-600 custom-grey-border text-uppercase mx-2"
                        :color="row.project_status_obj.color"
                        :text-color="row.project_status_obj.textcolor"
                      >
                        {{ row.project_status_obj.text }} 
                      </v-chip>
                      <span v-else>-</span>
                    </td>
                  </tr>
                </v-hover>
              </tbody>
              <tfoot v-else>
                <tr>
                  <td colspan="8">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-14"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no lead at the moment.
                    </p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </v-col>
    </v-row>
    <MeetingUpdate
      :db-meeting="update_meeting"
      :db-dialog="update_dialog"
      v-on:close="update_dialog = false"
      v-on:success="getDashboard()"
    ></MeetingUpdate>
  </v-container>
</template>
<script>
import moment from "moment-timezone";
import Notes from "@/view/pages/Notes";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { QUERY, GET, DELETE } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import MeetingUpdate from "@/view/pages/partials/Detail/Meeting-Update";
import DeleteSmallConfirm from "@/view/pages/partials/DeleteSmallConfirm";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "dashboard-new",
  mixins: [CommonMixin],
  data() {
    return {
      selectedItem: 1,
      series: [],
      chartOptions: {
        colors: ["#ff9800", "#0cbc40", "#ff3b3b"],
        fill: {
          type: ["solid"],
          colors: ["#ff9800", "#0cbc40", "#ff3b3b"],
        },
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          title: {
            text: "SGD $ (thousands)",
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "SGD $ " + val + " thousands";
            },
          },
        },
      },
      appointments: [],
      quote_image: null,
      lead: {
        appointment: 0,
        prospect: 0,
        dead: 0,
        total: 0,
        customer: 0,
      },
      leads: [],
      notes: [],
      quote: null,
      user_name: null,
      is_id_executive: false,
      is_lead_pending: false,
      update_dialog: false,
      pageLoading: false,
      deleteLoading: false,
      fyear: false,
      fyearList: [],
      update_meeting: {},
      leadByStatus: [],
      leadByStages: [],
      fstatusList: [],
      fmonthList: [],
      fidList: [],
      leadByStatusFilter: {
        id_user: null,
        id_user_text: null,
        search: null,
        status: null,
        year: null,
        month: null,
      },
      leadByStageFilter: {
        id_user: null,
        id_user_text: null,
        search: null,
        status: null,
        year: null,
        month: null,
      },
    };
  },
  components: {
    Notes,
    Barcode,
    MeetingUpdate,
    DeleteSmallConfirm,
  },
  methods: {
    filterStatus(type, item) {
      if (type == "id_user") {
        this.leadByStatusFilter.id_user = item.value;
        this.leadByStatusFilter.id_user_text = item.text;
      }
      if (type == "status") {
        this.leadByStatusFilter.status = item.value;
      }
      if (type == "year") {
        this.leadByStatusFilter.year = item.value;
      }
      if (type == "month") {
        this.leadByStatusFilter.month = item.value;
      }
      this.$nextTick(() => {
        this.getLeadByStatus();
      });
    },
    filterStage(type, item) {
      if (type == "id_user") {
        this.leadByStageFilter.id_user = item.value;
        this.leadByStageFilter.id_user_text = item.text;
      }
      if (type == "status") {
        this.leadByStageFilter.status = item.value;
      }
      if (type == "year") {
        this.leadByStageFilter.year = item.value;
      }
      if (type == "month") {
        this.leadByStageFilter.month = item.value;
      }
      this.$nextTick(() => {
        this.getLeadByStages();
      });
    },
    edit_meeting(row) {
      this.update_dialog = true;
      this.update_meeting = row;
    },
    delete_meeting(meetingId) {
      this.deleteLoading = true;
      this.$store
        .dispatch(DELETE, { url: "meetings", data: { meetings: [meetingId] } })
        .then(() => {
          this.getDashboard();
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    getDashboard() {
      this.pageLoading = true;
      this.$store
        .dispatch(GET, { url: "dashboard-new" })
        .then(({ data }) => {
          this.is_id_executive = data.is_id_executive;

          if (data.is_id_executive) {
            this.filterStatus("id_user", {
              value: data.user_id,
              text: data.user_name,
            });
          } else {
            this.filterStatus("id_user", this.lodash.head(data.id_users));
          }

          if (data.is_id_executive) {
            this.filterStage("id_user", {
              value: data.user_id,
              text: data.user_name,
            });
          } else {
            this.filterStage("id_user", this.lodash.head(data.id_users));
          }

          this.appointments = data.appointments;
          this.lead = data.lead;
          this.notes = data.notes;
          this.quote = data.quote;
          this.quote_image = data.quote_image;
          this.user_name = data.user_name;
          this.is_lead_pending = data.is_lead_pending;
          this.fidList = data.id_users;
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    getLeadByStatus() {
      this.$store
        .dispatch(QUERY, {
          url: "lead-status",
          data: { ...this.leadByStatusFilter },
        })
        .then(({ data }) => {
          this.leadByStatus = data;
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    getLeadByStages() {
      this.$store
        .dispatch(QUERY, {
          url: "lead-stage",
          data: { ...this.leadByStageFilter },
        })
        .then(({ data }) => {
          this.leadByStages = data;
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    getLeadValue() {
      this.series = [
        {
          name: "All Leads",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Lead to Prospect",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Dead Lead",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        }
      ];
      this.$store
        .dispatch(GET, { url: `lead-value?year=${this.fyear}` })
        .then(({ data }) => {
          this.series = data;
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    filterYear(param) {
      this.fyear = param.value;
      this.$nextTick(() => {
        this.getLeadValue();
      });
    },
  },
  beforeMount() {
    this.fyear = moment().format("YYYY");

    this.fstatusList = [];

    this.fstatusList.push({
      text: "All Lead",
      value: "all_lead",
    });

    this.fstatusList.push({
      text: "Dead Lead",
      value: "dead_lead",
    });

    this.fstatusList.push({
      text: "Appointment Made",
      value: "appointment_made",
    });

    this.fstatusList.push({
      text: "To Prospect",
      value: "to_prospect",
    });

    this.fstatusList.push({
      text: "To Customer",
      value: "to_customer",
    });

    this.leadByStatusFilter.status = "all_lead";
    this.leadByStageFilter.status = "all_lead";

    this.leadByStatusFilter.year = moment().format("YYYY");
    this.leadByStatusFilter.month = this.lodash.toLower(
      moment().format("MMMM")
    );

    this.leadByStageFilter.year = moment().format("YYYY");
    this.leadByStageFilter.month = this.lodash.toLower(moment().format("MMMM"));

    this.fyearList.push({
      text: moment().subtract(1, "year").format("YYYY"),
      value: moment().subtract(1, "year").format("YYYY"),
    });

    this.fyearList.push({
      text: moment().format("YYYY"),
      value: moment().format("YYYY"),
    });

    this.fyearList.push({
      text: moment().add(1, "year").format("YYYY"),
      value: moment().add(1, "year").format("YYYY"),
    });

    this.fmonthList = [];
    for (let i = 1; i < 13; i++) {
      this.fmonthList.push({
        text: moment(`2022-${i}-01`).format("MMMM"),
        value: this.lodash.toLower(moment(`2022-${i}-01`).format("MMMM")),
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);

    this.getDashboard();
    this.getLeadValue();
  },
};
</script>
