<template>
  <div class="bg-white dbNotes-card">
    <div class="px-5 pt-5 d-flex">
      <h3>My Notes</h3>
      <v-spacer></v-spacer>
      <v-btn
        v-if="dbNotesCheckedIds.length"
        tile
        depressed
        fab
        small
        height="30"
        width="30"
        class="mr-2 red lighten-4"
        @click="removeNotes()"
      >
        <v-icon size="16" icon color="red">mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="dbNotes.length"
        tile
        depressed
        fab
        small
        height="30"
        width="30"
        class="mr-2 blue lighten-4"
        @click="addNote()"
      >
        <v-icon size="16" icon color="blue">mdi-plus</v-icon>
      </v-btn>
    </div>
    <div class="py-2 pt-2">
      <div
        class="px-5 overflow-auto d-flex flex-column"
        style="max-height: 500px"
      >
        <table class="w-100 note-table" v-if="dbNotes.length">
          <tr
            v-for="(row, index) in dbNotes"
            :key="index"
            class="blue lighten-5"
          >
            <td valign="top" width="40px">
              <v-checkbox
                hide-details
                v-model="dbNotesCheckedIds"
                class="ma-1"
                color="blue"
                :value="row.id"
                multiple
              ></v-checkbox>
            </td>
            <td class="">
              <v-textarea
                class="note-area border-0"
                name="input-7-1"
                filled
                auto-grow
                hide-details
                rows="1"
                width="100%"
                :ref="`textarea-${index}`"
                v-model="dbNotes[index].text"
                @keypress.prevent.enter="addNoteNextLine()"
                @keyup.delete="removeNote(index)"
                @focus="updatedIndex(index)"
              >
              </v-textarea>
            </td>
          </tr>
        </table>
        <div v-else class="outer-event d-flex justify-center" style="">
          <div
            class="py-5 px-10 text-center my-10 cursor-pointer"
            style="border: 2px dashed grey; font-weight: 500; color: grey"
            @click="addNote()"
          >
            <v-icon size="20" color="grey">mdi-plus</v-icon><br />
            <span>Create Note</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PATCH } from "@/core/services/store/request.module";

export default {
  name: "mine-notes",
  data() {
    return {
      activeIndex: null,
      dbNotesCheckedIds: [],
      dbNotes: [],
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    notes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    notes: {
      deep: true,
      handler(param) {
        this.dbNotes = param;
      },
    },
    dbNotes: {
      deep: true,
      handler() {
        this.updateNotes();
      },
    },
  },
  methods: {
    updateNotes() {
      const _this = this;

      const db_notes = _this.lodash.filter(_this.dbNotes, function (param) {
        return param.text !== null;
      });

      clearTimeout(_this.timeout);

      _this.timeout = setTimeout(function () {
      _this.$store
        .dispatch(PATCH, { url: "notes", data: { notes: db_notes } })
        .then(({ data }) => {
          console.log({ data });
        })
        .catch((error) => {
          console.log({ error });
        });
    }, _this.timeoutLimit);
    },
    addNote() {
      this.dbNotes.push({
        id: Date.now(),
        text: null,
      });
      const length = this.dbNotes.length;
      this.$nextTick(() => {
        const element = this.$refs[`textarea-${length - 1}`];
        const area = element[0].$el.querySelector("textarea");
        area.focus();
      });
    },
    addNoteNextLine() {
      if (this.activeIndex >= 0) {
        this.dbNotes.splice(this.activeIndex + 1, 0, {
          id: Date.now(),
          text: null,
        });
        this.$nextTick(() => {
          const element = this.$refs[`textarea-${this.activeIndex + 1}`];
          const area = element[0].$el.querySelector("textarea");
          area.focus();
        });
      }
    },
    removeNotes() {
      const updatedNotes = this.dbNotes.filter((row) => {
        return !this.dbNotesCheckedIds.includes(row.id);
      });
      this.dbNotes = updatedNotes;
      this.dbNotesCheckedIds = [];
    },
    removeNote(index) {
      if (!this.dbNotes[index].text) {
        this.dbNotes.splice(index, 1);
        if (this.dbNotes.length) {
          this.$nextTick(() => {
            const element = this.$refs[`textarea-${index - 1}`];
            const area = element[0].$el.querySelector("textarea");
            area.focus();
          });
        }
      }
    },
    updatedIndex(index) {
      this.activeIndex = index;
    },
  },
};
</script>
